@import 'material-icons/iconfont/material-icons.scss';
/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500;700;900&display=swap'); */

@font-face {
  font-family: Rubik;
  src: url(../assets/fonts/Rubik-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: Rubik;
  src: url(../assets/fonts/Rubik-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: Rubik;
  src: url(../assets/fonts/Rubik-Bold.ttf);
  font-weight: 700;
}

@tailwind base;
@tailwind components;


html, body {
  position: fixed;
  overflow: hidden;
  @apply w-full;
  @apply h-full;
  overscroll-behavior-x: contain;
  touch-action: none;
  font-family: 'Rubik', sans-serif;
}

h1 {
  font-size: 7rem;
  -webkit-text-stroke: 4px  #4DB39F;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #ffffff;
  line-height: 0.9;
}

h2 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #4DB39F;
}

h3 {
 font-size: 3rem;
  -webkit-text-stroke: 2px  #4DB39F;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #ffffff; 
}

.btn-text {
  @apply  h-16 w-64 rounded-full mb-4 text-app-green bg-white font-semibold;
}

.btn-icon {
  @apply ml-4 bg-white text-app-green rounded-full w-8 h-8;
}

@tailwind utilities;
